@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap");

$light-grey-primary: #666666;
$light-grey-secondary: #d1d2d4;
$grey: #9ba4af;
$white: #fff;
$box-shadow: #0000000f;
$orange: #ee7623;
$primary-font-family: "Raleway", sans-serif;

body{
  margin:0;
  font-family: $primary-font-family;
}
.coming-soon {
  background-image: url("/../public/images/logoBg.png");
  height: 100vh;
  flex-direction: column !important;
  padding: 25px;
  padding-bottom: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: space-between;
  .coming-soon-head {
    img.app-logo {
      height: 65px;
    }
  }
  .coming-soon-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img.coming_soon {
      width: 55%;
      padding:10px 0 50px 0;
    }
    form {
      width: 60%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .input-row {
        width: 100%;
        label {
          font-size: 20px;
          color: $light-grey-primary;
          margin-bottom: 5px;
        }
        input {
          background: $white;
          border-color: $light-grey-secondary;
          padding: 12px;
          &:focus-visible {
            outline: none;
          }
        }
      }
      button.btn-notify {
        margin-top: 30px;
        padding: 12px 40px;
        text-transform: capitalize;
        font-size: 20px;
        line-height: 25px;
  border-radius: 50px !important;
  height: 46px;

      }
    }
    .connect-section {
      padding: 40px 0;
      .connect-text {
        font-size: 42px;
        color: $grey;
        text-align: center;
        font-family: "Raleway", sans-serif;

      }
      .social-icons {
        display: flex;
        margin-top: 10px;
        .list-item {
          .icon-btn {
            &:hover {
              background: none;
            }
            .icon-svg {
              min-width: 100%;
              background: $white;
              border-radius: 50%;
              width: 40px;
              height: 55px;
              display: flex;
              justify-content: center;
              align-items: center;
              color: $grey;
              box-shadow: 0px 3px 30px $box-shadow;
              border: 8px solid #ECECEC;
              &:hover {
                color: $orange;
              }
              svg {
                height: 32px;
                width: 32px;
              }
            }
          }
        }
      }
    }
  }
  footer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0;
    text-align: center;
    width: 100%;
    height: 50px;
    padding:0px 0px 10px 0px;
    p {
      margin: 0;
      color: #9ba4af;
      line-height: 24px;
    }
    a {
      color: #333;
      text-decoration: none;
      &:hover {
        color: $orange;
      }
    }
  }
}
//Responsive SCSS
@media (min-width: 1470px) {
  .coming-soon {
    .coming-soon-content {
      img.coming_soon {
        width: 45%;
      }
      form {
        width: 40%;
      }
    }
    .connect-section {
      padding-bottom: 0 !important;
      .social-icons {
        .list-item {
          .icon-btn {
            .icon-svg {
              width: 30px !important;
              height: 45px !important;
              svg {
                height: 25px !important;
                width: 25px !important;
              }
            }

          }
        }
      }
    }
  }
}
@media (max-width: 1023px) and (min-width: 620px) {
  .coming-soon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .connect-section {
      padding-bottom: 0 !important;
      .connect-text {
        font-size: 30px !important;
      }
      .social-icons {
        .list-item {
          .icon-btn {
            .icon-svg {
              width: 20px !important;
              height: 35px !important;
              svg {
                height: 20px !important;
                width: 20px !important;
              }
            }
           
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .coming-soon {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .coming-soon-head {
      text-align: center;
    }
    .coming-soon-content {
      img.coming_soon {
        width: 85%;
      }
      form {
        width: 100%;
        label {
          font-size: 18px !important;
          margin-bottom: 10px !important;
        }
      }
    }
    .connect-section {
      padding-bottom: 0 !important;
      .connect-text {
        font-size: 24px !important;
      }
      .social-icons {
        margin: 0 !important;
        .list-item {
          .icon-btn {
            // padding: 7px;
            .icon-svg {
              width: 20px !important;
              height: 35px !important;
              svg {
                height: 20px !important;
                width: 20px !important;
              }
            }
          }
        }
      }
    }
  }
}
